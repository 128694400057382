<template>
    <GlobalDialog :disable-backdrop="true" :title="props.title" @close="close">
        <div class="avv-editor avv-theme-nego custom-font agreement-html agreement-init no-w-full" style="width: calc(100% - 40px)">
            <div class="avv-container-wrap">
                <div class="avv-container no-h-calc h-full" v-html="props.html"></div>
            </div>
        </div>
    </GlobalDialog>
</template>

<script setup lang="ts">
import GlobalDialog from "./GlobalDialog.vue";

const props = defineProps<{
    html: string;
    title: string;
}>();

const emit = defineEmits(['callback']);

const close = () => emit('callback', null)

</script>
