import { IsHTMLElement } from "../features/dom_utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";
import FootnoteDialog from "../features/dialogs/FootnoteDialog.vue"

export interface FootnotesController extends ExposeStimulus<typeof FootnotesController> {}
export class FootnotesController extends StimulusControllerBase {
  static targets = ['editor'] as const
  
  connect(): void {
      this.initializeFootnotesListeners()
  }

  initializeFootnotesListeners(){
    this.editorTarget.addEventListener('click', e => {
        if(!(IsHTMLElement(e.target))) return
        const isTargetFootnote = e.target.tagName == 'AVV-FOOTNOTE'
        if(isTargetFootnote) this.onFootnoteClick(e.target)
    })
  }

  onFootnoteClick(footnoteElement: HTMLElement){
    const footnoteId = footnoteElement.getAttribute('data-id')
    if(!footnoteId) return
    const footnoteHTML = this.getFootnoteHTMLById(footnoteId)
    const footnoteCount = this.getFootnoteCoundById(footnoteId)
    if(!footnoteHTML || !footnoteCount) return
    this.openFootnoteModal(footnoteHTML, footnoteCount)
  }

  getFootnoteHTMLById(id: string): string | null {
    const footnoteElement = document.querySelector(`avv-footnotes avv-footnote[data-id="${id}"]`)
    if(!footnoteElement) return null
    return footnoteElement.innerHTML
  }

  getFootnoteCoundById(id: string): number | null {
    const footnoteElement = document.querySelector(`avv-footnote[data-id="${id}"]`)
    if(!footnoteElement) return null
    return parseInt(footnoteElement.textContent || '0')
  }

  openFootnoteModal(footnoteHTML: string, footnoteCount: number){
    // TODO(oskar): ensure sanitization of footnoteHTML
    // localize footnote count
    avv_dialog({vueDialog: FootnoteDialog, props: {html: footnoteHTML, title: `Footnote ${footnoteCount}`}, callback: () => {}})
  }
  
}

export default FootnotesController
